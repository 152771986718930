<template>
    <dynamic-link
        v-if="!isContext"
        tabindex="0"
        class="a-button"
        :class="className"
        :to="to"
        :target="target"
        :title="computedTitle"
        :event="gaEvent"
        :type="to ? null : nativeType"
        :native-type="nativeType"
        @click="handleClick"
    >
        <v-clamp
            v-if="text && !onlyIcon"
            class="a-button__content"
            tag="span"
            :max-lines="maxLines"
        >
            {{ text }}
        </v-clamp>
        <a-glyph v-if="glyph && layout !== 'no-icon'" class="a-button__glyph" :name="glyph" />
    </dynamic-link>
    <div v-else class="a-button-with-context">
        <dynamic-link
            class="a-button"
            tabindex="0"
            :class="className"
            :to="to"
            :target="target"
            :title="title"
            :event="gaEvent"
            :type="to ? null : nativeType"
            :native-type="nativeType"
            @click="handleClick"
        >
            <v-clamp
                v-if="text && !onlyIcon"
                class="a-button__content"
                tag="p"
                :max-lines="maxLines"
            >
                {{ text }}
            </v-clamp>
            <a-glyph v-if="glyph && layout !== 'no-icon'" class="a-button__glyph" :name="glyph" />
        </dynamic-link>
        <div class="a-button-with-context__text">
            {{ context }}
        </div>
    </div>
</template>

<script>
import { upperFirst } from 'lodash';
import VClamp from 'vue-clamp';
import { TYPES, SIZES, LAYOUTS, BORDER_RADIUS } from '@core/components/button/constants.js';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'AButton',

    components: {
        AGlyph,
        DynamicLink,
        VClamp,
    },
    mixins: [breakpoint],
    props: {
        /**
         * Button text
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Button title
         */
        title: {
            type: String,
            default: undefined,
        },

        /**
         * Link URL
         */
        to: {
            type: String,
            default: null,
        },

        target: {
            type: String,
            default: () => undefined,
        },

        /**
         * Button type
         */
        type: {
            type: String,
            default: 'secondary',
            validator: (value) => !value || TYPES.includes(value),
        },

        /**
         * Button native type
         */
        nativeType: {
            type: String,
            default: 'button',
        },

        /**
         * Button layout
         */
        layout: {
            type: String,
            default: 'right-icon',
            validator: (value) => !value || LAYOUTS.includes(value),
        },

        /**
         * Button size
         */
        size: {
            type: String,
            default: 'medium',
            validator: (value) => !value || SIZES.includes(value),
        },

        /**
         * Border radius
         */
        borderRadius: {
            type: Number,
            default: 4,
            validator: (value) => !value || BORDER_RADIUS.includes(value),
        },
        /**
         * Disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Glyph name
         */
        glyph: {
            type: String,
            default: undefined,
        },

        /**
         * Context present
         */
        isContext: {
            type: Boolean,
            default: false,
        },

        /**
         * Context
         */
        context: {
            type: String,
            default: undefined,
        },

        /**
         * GA Event
         */
        event: {
            type: Object,
            default: undefined,
        },
        modalEvents: {
            type: Object,
            default: undefined,
        },

        maxLines: {
            type: Number,
            default: 1,
        },
    },

    emits: ['click'],
    data() {
        return {
            elem: null,
        };
    },

    computed: {
        onlyIcon() {
            if (this.layout === 'only-icon') return true;
            return this.glyph && !this.text;
        },
        /**
         * Returns classes for component
         *
         * @returns {[className]: condition}
         */
        className() {
            const { type, size, layout, maxLines, borderRadius, disabled, glyph, context } = this;
            return [
                'a-button_type',
                'a-button_layout',
                `a-button_type_${type}`,
                `a-button_size_${size}`,
                `a-button_layout_${layout}`,
                `a-button_radius_${borderRadius}`,
                `a-button_maxlines_${maxLines}`,
                {
                    'a-button_with-glyph': glyph,
                    'a-button_only-icon': this.onlyIcon,
                    'a-button_disabled': disabled,
                    'a-button-with-context__button': context,
                },
            ];
        },
        computedTitle() {
            if (!this.onlyIcon) return this.title;
            return this.text;
        },

        gaEvent() {
            if (this.event?.doNotSendGA) {
                return this.event;
            }

            return {
                event: 'Acronis',
                category: 'Conversions',
                action: upperFirst(this.text?.toLowerCase().trim()) || '[no action]',
                label: this.to || '[no label]',
                context: 'click',
                ...this.event,
            };
        },
    },

    mounted() {
        this.elem = this.$el;
    },

    methods: {
        handleClick(event) {
            if (this.modalEvents) {
                if (this.modalEvents.forDesktop && !this.isDesktop) return;
                localStorage.setItem('modalEvents', JSON.stringify(this.modalEvents));
            }
            this.$emit('click', event);
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-button {
    --a-button-outline: none;
    --a-button-border: none;
    --a-button-opacity: 1;
    --a-button-background: transparent;
    --a-button-text-order: 1;
    --a-button-icon-order: 2;

    display: inline-flex;
    gap: 8px;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    padding: var(--a-button-padding);
    border-radius: var(--a-button-border-radius);
    color: var(--a-button-color);
    background: var(--a-button-background);
    border: var(--a-button-border);
    opacity: var(--a-button-opacity);
    outline: var(--a-button-outline);

    &:focus-visible {
        --a-button-outline: 3px solid var(--av-fixed-focus);
    }
    &_maxlines_1 {
        white-space: nowrap;
    }
    &_with-glyph {
        align-items: center;
        justify-content: space-between;
    }
    &__glyph {
        order: var(--a-button-icon-order);
        height: 16px;
        width: 16px;
        fill: var(--a-button-fill);
        margin: 0;
    }

    &_type {
        &_main {
            --a-button-color: var(--av-inversed-primary);
            --a-button-fill: var(--av-inversed-primary);
            --a-button-background: var(--av-button-main);
            &:hover {
                --a-button-background: var(--av-button-main-hover);
            }
            &:active {
                --a-button-background: var(--av-button-main-active);
            }
            &.a-button_disabled {
                --a-button-background: var(--av-button-main-light);
                --a-button-color: var(--av-inversed-light);
                --a-button-fill: var(--av-inversed-light);
            }
        }
        &_action {
            --a-button-color: var(--av-inversed-primary);
            --a-button-fill: var(--av-inversed-primary);
            --a-button-background: var(--av-button-action);
            &:hover {
                --a-button-background: var(--av-button-action-hover);
            }
            &:active {
                --a-button-background: var(--av-button-action-active);
            }
            &.a-button_disabled {
                --a-button-background: var(--av-button-action-light);
                --a-button-color: var(--av-inversed-light);
                --a-button-fill: var(--av-inversed-light);
            }
        }
        &_secondary {
            --a-button-color: var(--av-link-primary);
            --a-button-border: 1px solid var(--av-button-secondary);
            --a-button-fill: var(--av-link-primary);
            &:hover {
                --a-button-background: var(--av-button-secondary-hover);
            }
            &:active {
                --a-button-background: var(--av-button-secondary-active);
            }
            &.a-button_disabled {
                --a-button-color: var(--av-link-primary-light);
                --a-button-border: 1px solid var(--av-button-secondary-light);
                --a-button-fill: var(--av-link-primary-light);
            }
        }
        &_inverse {
            --a-button-color: var(--av-link-inversed);
            --a-button-fill: var(--av-link-inversed);
            --a-button-border: 1px solid var(--av-button-inversed);
            &:hover {
                --a-button-background: var(--av-button-inversed-hover);
            }

            &:active {
                --a-button-background: var(--av-button-inversed-active);
            }
            &.a-button_disabled {
                --a-button-color: var(--av-link-inversed-light);
                --a-button-border: 1px solid var(--av-button-inversed-light);
                --a-button-fill: var(--av-link-inversed-light);
            }
        }
        &_ghost {
            --a-button-color: var(--av-link-primary);
            --a-button-fill: var(--av-link-primary);
            &:hover {
                --a-button-background: var(--av-button-secondary-hover);
            }

            &:active {
                --a-button-background: var(--av-button-secondary-active);
            }
            &.a-button_disabled {
                --a-button-color: var(--av-link-primary);
                --a-button-fill: var(--av-link-primary);
            }
        }
    }

    &_disabled {
        pointer-events: none;

        --a-button-opacity: 0.3;
        &:focus-visible {
            --a-button-outline: none;
        }
    }

    &_radius {
        &_0 { --a-button-border-radius: 0px }
        &_2 { --a-button-border-radius: 2px }
        &_4 { --a-button-border-radius: 4px; }
        &_8 { --a-button-border-radius: 8px; }
        &_12 { --a-button-border-radius: 12px; }
        &_16 { --a-button-border-radius: 16px; }
        &_20 { --a-button-border-radius: 20px; }
        &_24 { --a-button-border-radius: 24px; }
        &_28 { --a-button-border-radius: 28px; }
    }

    &_size {
        &_large {
            --a-button-padding: 16px;
            @mixin lead-accent;
        }
        &_medium, &_m {
            --a-button-padding: 12px 16px;
            @mixin paragraph-accent;
        }
        &_small, &_s {
            --a-button-padding: 8px 16px;
            @mixin paragraph-accent;
        }
        &_tiny {
            --a-button-padding: 4px 8px;
            @mixin paragraph-accent;
        }
        &_micro {
            --a-button-padding: 4px 8px;
            @mixin caption-accent;
        }
    }

    &__content {
        order: var(--a-button-text-order);
    }

    &_layout {
        &_left-icon {
            --a-button-text-order: 2;
            --a-button-icon-order: 1;
        }
    }

    &-with-context {
        position: relative;
        text-align: center;

        @media (--viewport-desktop) {
            margin-bottom: -24px;
        }

        &__button {
            width: 100%;
        }

        &__text {
            display: none;
            @media (--viewport-desktop) {
                @mixin caption;
                display: block;
                position: absolute;
                margin-top: 8px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.a-button.a-button_layout_only-icon {
    &.a-button_size_large {
        --a-button-padding: 20px;
    }
    &.a-button_size_medium, &.a-button_size_m {
        --a-button-padding: 16px;
    }
    &.a-button_size_small, &.a-button_size_s {
        --a-button-padding: 12px;
    }
    &.a-button_size_tiny {
        --a-button-padding: 8px;
    }
    &.a-button_size_micro {
        --a-button-padding: 4px;
    }
}
</style>
